import React from "react";
import { Routes, Route } from "react-router-dom";

import PublicOrRoleRequired from "../auth/PublicOrRoleRequired";
import RequireAuth from "../auth/RequireAuth";
import Home from "../../pages/Home";
import Design from "../../pages/Design";
import About from "../../pages/About";
import Shop from "../../pages/Shop";
import RoomItems from "../../pages/RoomItems";
import OrderSample from "../../pages/OrderSample";
import ReadyToBuy from "../../pages/ReadytoBuy";
import Product from "../../pages/Product";
import FindStyle from "../../pages/FindYourStyle/FindStyle";
import Cart from "../../pages/Cart";
import CheckOut from "../../pages/Cart/CheckOut";
import ContactUs from "../../pages/ContactUs";
import TermsOfService from "../../pages/TermsOfService";
import Login from "../../pages/Login";
import Profile from "../../pages/Profile";
import Signup from "../../pages/Signup";
import FAQ from "../../pages/FAQs/FAQs";
import BookConsultant from "../../pages/BookConsultant";
import MainLayout from "../../layout/MainLayout";
import ProfileFetcher from "../auth/ProfileFetcher";
import RequireSessionApproval from "../auth/RequireSessionApproval";
import GetDesign from "../designs/GetDesign";
import ROUTES from "../../constants/routes";
import useSetDocumentTitleFromRoute from "../../hooks/useSetDocumentTitle";
import useScrollToTopOnRouteChange from "../../hooks/useScrollToTopOnRouteChange";
import useRouteTracker from "../../hooks/useRouteTracker";
import NotFound from "../../pages/NotFound";
import ForgetPassword from "../../pages/ForgetPassword";
import PaymentStatus from "../../pages/PaymentStatus";
import { USER_ROLES } from "../../constants/userRoles";

const AppRoutes = () => {
  useSetDocumentTitleFromRoute();
  useScrollToTopOnRouteChange();
  useRouteTracker();

  return (
    <Routes>
      <Route element={<ProfileFetcher />}>
        <Route path={ROUTES.HOME.path} element={<Home />} />
        <Route element={<MainLayout />}>
          <Route element={<RequireAuth />}>
            <Route element={<RequireSessionApproval />}>
              <Route element={<GetDesign />}>
                <Route
                  path={`${ROUTES.DESIGNS.path}/:itemName/designs/:designId`}
                  element={<Design />}
                />
              </Route>
            </Route>

            <Route
              path={ROUTES.PROFILE.path}
              element={
                <PublicOrRoleRequired
                  element={Profile}
                  allowedRoles={[USER_ROLES.USER, USER_ROLES.CONSULTANT]}
                />
              }
            />

            <Route
              path={ROUTES.CART.path}
              element={
                <PublicOrRoleRequired
                  element={Cart}
                  allowedRoles={[USER_ROLES.USER]}
                />
              }
            />
            <Route
              path={ROUTES.CHECKOUT.path}
              element={
                <PublicOrRoleRequired
                  element={CheckOut}
                  allowedRoles={[USER_ROLES.USER]}
                />
              }
            />
          </Route>

          <Route path={ROUTES.SIGN_IN.path} element={<Login />} />
          <Route path={ROUTES.SIGN_UP.path} element={<Signup />} />
          <Route
            path={ROUTES.FORGET_PASSWORD.path}
            element={<ForgetPassword />}
          />
          <Route path={ROUTES.ABOUT_US.path} element={<About />} />
          <Route path={ROUTES.CONTACT_US.path} element={<ContactUs />} />
          <Route path={ROUTES.TERMS_OF_SERVICE.path} element={<TermsOfService />} />
          <Route path={ROUTES.CUSTOM_DESIGN.path} element={<Shop />} />
          <Route path={ROUTES.CONSULTANT.path} element={<BookConsultant />} />
          <Route path={`${ROUTES.CUSTOM_DESIGN.path}/rooms/:roomId`} element={<RoomItems />} />
          <Route path={ROUTES.STYLE.path} element={<FindStyle />} />
          <Route path={ROUTES.SAMPLE.path} element={<OrderSample />} />
          <Route path={ROUTES.LIMITED_COLLECTION.path} element={<ReadyToBuy />} />

          <Route
            path={`${ROUTES.PRODUCT.path}/:productId`}
            element={<Product />}
          />
          <Route path={ROUTES.FAQ.path} element={<FAQ />} />
          <Route
            path={ROUTES.PAYMENT_STATUS.path}
            element={<PaymentStatus />}
          />
          <Route path="" element={<NotFound />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
