import React, { useMemo } from "react";
import { useQuery } from "react-query";
import MoonLoader from "react-spinners/MoonLoader";

import CustomDatePicker from "../../../components/datePicker/DatePicker";
import CardWrapper from "../../../components/cardWrapper";
import useSnackbar from "../../../components/snackbar/hooks/useSnackbar";
import { getAvailableTimeSlots } from "../../../apis/consultationApi";
import { formatDateToTime } from "../../../utils/dates";
import { BOOKING_SLOTS } from "../../../queries-keys/consultation";
import { getErrorMessage } from "../../../utils/errors";
import { getFormattedDayAndMonth, formatDateToYYYYMMDD } from "../../../utils/dates";

const SET_BOOKING_DATE = "SET_BOOKING_DATE";
const SET_BOOKING_TIME_SLOT = "SET_BOOKING_TIME_SLOT";

const setAppointmentDate = (date) => ({
  type: SET_BOOKING_DATE,
  payload: date,
});

const setAppointmentTimeSlot = (timeSlot) => ({
  type: SET_BOOKING_TIME_SLOT,
  payload: timeSlot,
});

function SlotsSelector({ bookingDetails, bookingDispatch }) {
  const { handleOpenSnackbar } = useSnackbar();

  const { data: timeSlots, isLoading } = useQuery(
    BOOKING_SLOTS(bookingDetails.type),
    () => getAvailableTimeSlots(bookingDetails.type),
    {
      enabled: !!bookingDetails.type,
      onSuccess: (timeSlots) => {
        if (timeSlots.length === 0) {
          return;
        }

        let minDate = new Date(timeSlots[0].start);
        for (let i = 1; i < timeSlots.length; i++) {
          const currentDate = new Date(timeSlots[i].start);
          if (currentDate < minDate) {
            minDate = currentDate;
          }
        }

        !bookingDetails.date && handleSelectDate(minDate);
      },
      onError: (error) => {
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
    }
  );

  const filteredTimeSlots = useMemo(() => {
    if (!bookingDetails.date) {
      return [];
    }

    const filterDate = new Date(bookingDetails.date);

    return timeSlots?.filter((slot) => {
      const timeSlotDate = new Date(slot.start);

      return (
        timeSlotDate.getDate() === filterDate.getDate() &&
        timeSlotDate.getMonth() === filterDate.getMonth() &&
        timeSlotDate.getFullYear() === filterDate.getFullYear()
      );
    });
  }, [timeSlots, bookingDetails.date]);

  const handleSelectDate = (date) => {
    const formattedDated = formatDateToYYYYMMDD(date);
    bookingDispatch(setAppointmentTimeSlot(undefined));
    bookingDispatch(setAppointmentDate(formattedDated));
  };

  const handleSelectTimeSlot = (timeSlot) => {
    timeSlot === bookingDetails.timeSlot
      ? bookingDispatch(setAppointmentTimeSlot(undefined))
      : bookingDispatch(setAppointmentTimeSlot(timeSlot));
  };

  return (
    <>
      <div className="flex flex-col space-y-5">
        <span className="text-[16px] font-bold">Date</span>
        <CustomDatePicker
          selectedDate={bookingDetails.date && new Date(bookingDetails.date)}
          includeDates={timeSlots?.map((slot) => new Date(slot.start))}
          onChange={handleSelectDate}
        />
      </div>
      {isLoading ? (
        <div className="col-span-2 flex items-center justify-center p-2">
          <MoonLoader color="#24595C" />
        </div>
      ) : (
        <div className="col-span-2 flex flex-col space-y-3">
          <span className="text-[16px] font-bold">Time Slots</span>
          <span className="text-[14px] text-secondary">
            {filteredTimeSlots?.length
              ? `These are the available time slots on the ${getFormattedDayAndMonth(
                  bookingDetails.date
                )}.`
              : "There are no available time slots."}
          </span>
          {!!timeSlots?.length ? (
            <div className="!mb-5 grid grid-cols-2 gap-5 md:grid-cols-4 lg:mb-0">
              {filteredTimeSlots?.map((slot) => {
                return (
                  <React.Fragment key={slot.id}>
                    <div className="text-primary ">
                      <CardWrapper
                        isSelected={bookingDetails.timeSlot?.id === slot.id}
                        onClick={() => handleSelectTimeSlot(slot)}
                      >
                        <div className="flex h-full w-full items-center justify-evenly p-2">
                          <span className={`text-nowrap text-[14px] font-light`}>
                            {formatDateToTime(slot.start)}
                          </span>
                        </div>
                      </CardWrapper>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          ) : (
            <div className="!mx-auto flex flex-col space-y-3 text-center text-[14px] font-medium text-highlight lg:!mt-10 lg:text-[16px]">
              <p>Oops!</p>
              <p className="whitespace-pre-wrap">
                {` We are fully booked.
Additional slots will be added soon.`
                }
              </p>
              <p>Thank you for your patience.</p>
          </div>
          )}
          {/* <span className="!mt-auto text-sm font-light text-highlight">
                * Or the consultant can call you a day before to schedule the
                meeting.
              </span> */}
        </div>
      )}
    </>
  );
}

export default SlotsSelector;
