import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import ROUTES from "../constants/routes";
import useSnackbar from "../components/snackbar/hooks/useSnackbar";
import { createDesign } from "../apis/designsApi";
import { getErrorMessage } from "../utils/errors";

const useCreateDesign = () => {
  const navigate = useNavigate();
  const { handleOpenSnackbar } = useSnackbar();

  const { mutate: createDesignMutation, isLoading: isCreatingDesign } =
    useMutation((itemId) => createDesign(itemId), {
      onError: (error) => {
        handleOpenSnackbar(
          getErrorMessage(error),
          "error",
        );
      },
      onSuccess: (design) => {      
        const itemName = design?.item?.name?.toLowerCase().split(" ").join("-") || design?.item; ;  
        navigate(`${ROUTES.DESIGNS.path}/${itemName}/designs/${design?.id}`);
      },
    });

  return {
    createDesignMutation,
    isCreatingDesign,
  };
};

export default useCreateDesign;
