import { createSlice } from "@reduxjs/toolkit";

import { PRICE_MODES } from "../constants/priceModes";
import {
  formatToCurrency,
  calculatePercentageAmount,
} from "../utils/numbers";
import { DESIGN_CATEGORY, LAYOUT_TYPES } from "../constants/designs";
import { CURRENCY } from "../constants/common";

const initialState = {
  id: null,
  layout: {
    layout_body: null,
    layout_size: null,
  },
  elements: {},
  prices: [],
  totalPrice: 0,
  totalPriceRangePercentage: 0,
  totalPricePerStep : [],
  totalPriceRangePercentagePerStep : [],
  isChanged: false,
  isLastChangeMaterial: false,
  image: null,
  lastDisplayedImage: null,
  priceMode: PRICE_MODES.ON,
  lastDimensionFilter: null
};

const designSlice = createSlice({
  name: "design",
  initialState,
  reducers: {
    init: (state, action) => {
      const {
        layout_body,
        layout_size,
        image,
        elements,
        id,
        price_mode = PRICE_MODES.ON,
      } = action.payload;
      state.id = id;
      state.layout.layout_body = layout_body;
      state.layout.layout_size = layout_size;
      state.elements = elements || {};
      state.isChanged = false;
      state.isLastChangeMaterial = false;
      state.image = image;
      state.priceMode = price_mode;
      recalculatePrices(state);
    },
    reset: () => {
      return initialState;
    },
    setLastDisplayedImage: (state, action) => {
      state.lastDisplayedImage = action.payload;
    },
    setLastDimensionFilter: (state, action) => {
      state.lastDimensionFilter = action.payload.newDimensionFilter
    },
    setShape: (state, action) => {
      const { elementId, elementName, shape } = action.payload;
      state.elements[elementId] = {
        ...state.elements[elementId],
        elementName,
        shape: shape,
      };
      state.isChanged = true;
      state.isLastChangeMaterial = false;
      generateDesignImagePath(state);
      recalculatePrices(state);
    },
    setMaterial: (state, action) => {
      const { elementId, elementName, material, materialName, isSet=false } = action.payload;
      const newMartial = isSet ? {} : state.elements[elementId]?.material || {};
      newMartial[materialName] = material;

      state.elements[elementId] = {
        ...state.elements[elementId],
        elementName,
        material: newMartial,
      };

      state.isChanged = true;
      state.isLastChangeMaterial = true;
      generateDesignImagePath(state);
      recalculatePrices(state);
    },
    setLayout: (state, action) => {
      const { layout_part, type } = action.payload;
      state.elements = {};
      if (type === LAYOUT_TYPES.LAYOUT_BODY)
        state.layout.layout_body =
          layout_part.id === state.layout.layout_body?.id ? null : layout_part;
      else if (type === LAYOUT_TYPES.LAYOUT_SIZE)
        state.layout.layout_size =
          layout_part.id === state.layout.layout_size?.id ? null : layout_part;

      state.isChanged = true;
      state.isLastChangeMaterial = false;
      generateDesignImagePath(state);
      recalculatePrices(state);
    },
    markAsChanged: (state) => {
      state.isChanged = true;
    },
    markAsUnchanged: (state) => {
      state.isChanged = false;
    },
  },
});

function generateDesignImagePath(state) {
  if (
    !Boolean(state?.layout?.layout_body?.id && state?.layout?.layout_size?.id)
  ) {
    state.image = null;
    return;
  }
  const codes = [];
  Object.keys(state.elements).forEach((element_id) => {
    const shape_code = state.elements[element_id]?.shape?.code;
    const materials = state.elements[element_id]?.material || {};
    const materials_codes = Object.keys(materials).sort().map(
      (material_name) => materials[material_name]?.code,
    );
    codes.push({ shape_code: shape_code, materials_codes: materials_codes });
  });
  let name = "";

  codes.sort((a, b) => a.shape_code.localeCompare(b.shape_code));
  codes.forEach((item, index) => {
    if (index === 0) name += "(";
    name += String(item.shape_code);
    if (index === codes.length - 1) name += ")";
    else name += ",";
  });

  codes.forEach((item, index) => {
    if (index === 0) name += "(";

    item.materials_codes.forEach((material_code, index2) => {
      if (index2 === 0) name += "[";
      name += String(material_code);
      if (index2 === item.materials_codes.length - 1) name += "]";
      else name += ",";
    });
    if (item.materials_codes.length === 0) {
      name += "[]";
    }

    if (index === codes.length - 1) name += ")";
    else name += ",";
  });

  state.image = name;
}

function recalculatePrices(state) {
  state.totalPrice = 0;
  state.prices = [];
  state.totalPricePerStep = [];
  state.totalPriceRangePercentagePerStep = [];
  let totalPriceR = 0;

  if (state.layout.layout_size) {
    const { name, price, dimensions, range_price_percentage } =
      state.layout.layout_size;

    state.totalPrice += +price;
    totalPriceR += calculatePercentageAmount(+price, +range_price_percentage);

    state.prices.push([
      name,
      dimensions ? dimensions : null,
      formatToCurrency(price, CURRENCY, range_price_percentage, state.priceMode),
    ]);
    state.totalPricePerStep.push(state.totalPrice);
    state.totalPriceRangePercentagePerStep.push(((totalPriceR - state.totalPrice) / state.totalPrice) * 100);
  }

  if (state.layout.layout_body) {
    const { name, price, dimensions, range_price_percentage } =
      state.layout.layout_body;

    state.totalPrice += +price;
    totalPriceR += calculatePercentageAmount(+price, +range_price_percentage);

    state.prices.push([
      name,
      dimensions ? dimensions : null,
      formatToCurrency(price, CURRENCY, range_price_percentage, state.priceMode),
    ]);
    state.totalPricePerStep.push(state.totalPrice);
    state.totalPriceRangePercentagePerStep.push(((totalPriceR - state.totalPrice) / state.totalPrice) * 100);
  }

  Object.keys(state?.elements || {}).forEach((elementId) => {
    const { elementName, shape } = state.elements[elementId];

    if (shape) {
      const { name, price, dimensions, range_price_percentage } = shape;

      state.totalPrice += +price;
      totalPriceR += calculatePercentageAmount(+price, +range_price_percentage);
      state.prices.push([
        name,
        dimensions ? dimensions : null,
        formatToCurrency(price, CURRENCY, range_price_percentage, state.priceMode),
      ]);
      state.totalPricePerStep.push(state.totalPrice);
      state.totalPriceRangePercentagePerStep.push(((totalPriceR - state.totalPrice) / state.totalPrice) * 100);
    }
  });

  Object.keys(state?.elements || {}).forEach((elementId) => {
    const { elementName, shape, material } = state.elements[elementId];
    if (material) {
      Object.keys(material).forEach((material_name) => {
        const { name, price, range_price_percentage } = material[material_name];
        state.totalPrice += +price;
        totalPriceR += calculatePercentageAmount(
          +price,
          +range_price_percentage,
        );
        state.prices.push([
          shape?.name + " - " + name,
          null,
          formatToCurrency(price, CURRENCY, range_price_percentage, state.priceMode),
        ]);
        state.totalPricePerStep.push(state.totalPrice);
        state.totalPriceRangePercentagePerStep.push(((totalPriceR - state.totalPrice) / state.totalPrice) * 100);
      });
    }
  });

  state.totalPriceRangePercentage = ((totalPriceR - state.totalPrice) / state.totalPrice) * 100;
}

export const {
  init,
  reset,
  setLastDisplayedImage,
  setLastDimensionFilter,
  setShape,
  setMaterial,
  setLayout,
  markAsChanged,
  markAsUnchanged,
} = designSlice.actions;

export default designSlice.reducer;
