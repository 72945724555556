import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Sling as Hamburger } from "hamburger-react";

import SideBar from "./SideBar";
import NavBar from "./NavBar";
import Badge from "../badge";
import ROUTES from "../../constants/routes";
import useGetCartItems from "../../hooks/useGetCartItems";
import ProfileMenu from "./ProfileMenu";
import SvgIcon from "../svgIcon";
import { CART_ICON_URL } from "../../constants/iconsUrls";

const Header = () => {
  const sideBarRef = useRef();
  const toggleButtonRef = useRef();
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const currentPath = useLocation().pathname;
  const { cartItems } = useGetCartItems();
  const cartElementsCount = cartItems?.length;

  const toggleSidebar = () => {
    setIsSideBarOpen((prevValue) => !prevValue);
  };

  useEffect(() => {
    const handleOutsideSideBarClick = (event) => {
      if (toggleButtonRef.current && toggleButtonRef.current.contains(event.target)) {
        return;
      }

      if (sideBarRef.current && !sideBarRef.current.contains(event.target)) {
        setIsSideBarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideSideBarClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideSideBarClick);
    };
  }, [sideBarRef]);

  return (
    <>
      <header className="relative z-40 flex h-fit w-full items-center justify-between px-2 py-2 lg:px-8 lg:py-5">
        <button
          ref={toggleButtonRef}
          className="rounded text-secondary lg:hidden"
          onClick={toggleSidebar}
        >
          <Hamburger size={28} toggled={isSideBarOpen} direction="left" />
        </button>
        <NavLink
          to={ROUTES.HOME.path}
          className="absolute left-1/2 -translate-x-1/2 lg:static lg:translate-x-0"
        >
          <img className="h-12 lg:h-14" src="/logo.svg" alt="logo" />
        </NavLink>
        <div className="hidden w-9/12 items-center justify-center lg:flex">
          <NavBar />
        </div>
        <div className="flex items-center justify-around space-x-4 pr-2 lg:space-x-8 lg:pr-0">
          <ProfileMenu />
          <NavLink to={ROUTES.CART.path}>
            <Badge content={cartElementsCount}>
              <SvgIcon
                src={CART_ICON_URL}
                className={`h-8 w-8 ${
                  currentPath === ROUTES.CART.path
                    ? "text-highlight"
                    : "text-secondary hover:text-primary"
                }`}
              />
            </Badge>
          </NavLink>
        </div>
      </header>
      {/* backdrop effect when the sidebar is opening */}
      <div
        className={`fixed left-0 top-0 z-30 !m-0 h-[100vh] w-full backdrop-blur-sm lg:hidden ${
          !isSideBarOpen && "hidden"
        }`}
      />
      <SideBar
        {...{
          isOpen: isSideBarOpen,
          setIsOpen: setIsSideBarOpen,
          innerRef: sideBarRef,
        }}
      />
    </>
  );
};

export default Header;
