import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";

import Tooltip from "../tooltip";
import SvgIcon from "../svgIcon";

function Step({
  label,
  icon,
  isCurrentStep = false,
  isCompleted = false,
  jumpingDisabled = false,
  onClick,
}) {
  return (
    <div className="flex w-full min-w-[28px] items-center">
      <Tooltip title={label}>
        <div
          className={`flex h-6 w-6 items-center justify-center overflow-hidden rounded-full border p-1 ${
            !jumpingDisabled && "cursor-pointer"
          } transition duration-500 ${
            isCurrentStep || isCompleted ? "border-primary" : "border-secondary"
          }`}
          onClick={!jumpingDisabled ? onClick : () => {}}
        >
          {icon && (
            <SvgIcon
              src={icon}
              className={`h-fill w-fill ${
                isCurrentStep || isCompleted ? "text-primary" : "text-secondary"
              }`}
            />
          )}
        </div>
      </Tooltip>
      <div className={`h-[2px] flex-1 ${isCompleted ? "bg-primary" : "bg-secondary"}`}></div>
    </div>
  );
}

export default Step;
