import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Intro from "./Intro";
import Background from "./Background";
import Loader from "../../components/loader";
import useGetAdminConfigs from "../../hooks/useGetAdminConfigs";
import { setHomePageVisited } from "../../features/appSlice";

const Home = () => {
  const introRef = useRef(null);
  const dispatch = useDispatch();
  const isHomePageVisited = useSelector((state) => state.app.isHomePageVisited);

  const handleHomePageVisited = () => {
    dispatch(setHomePageVisited());
  };

  const {
    configs: {
      backgroundImage,
      descriptionBody,
      descriptionImage,
      descriptionTitle,
    },
    isLoadingConfigs,
  } = useGetAdminConfigs();

  const startHandler = () => {
    document.body.style.overflow = "auto";

    introRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });

    setTimeout(() => {
      handleHomePageVisited();
    }, 2000);
  };

  useEffect(() => {
    if (!isHomePageVisited) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isHomePageVisited]);

  if (isLoadingConfigs) {
    return <Loader />;
  }

  return (
    <div className={`overflow-y-hidden scroll-smooth`}>
      {!isHomePageVisited && (
        <div className="h-dvh">
          <Background onStartNowClick={startHandler} image={backgroundImage} />
        </div>
      )}
      <Intro
        title={descriptionTitle}
        image={descriptionImage}
        body={descriptionBody}
        innerRef={introRef}
      />
    </div>
  );
};

export default Home;
