import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";

import Skeleton from "../../components/skeleton";
import ImageContainer from "../../components/imageContainer";
import SvgIcon from "../../components/svgIcon";
import useSnackbar from "../../components/snackbar/hooks/useSnackbar";
import useGetAdminConfigs from "../../hooks/useGetAdminConfigs";
import { getErrorMessage } from "../../utils/errors";
import { SOCIAL_MEDIA_QUERY_KEY } from "../../queries-keys/about";
import { getSocialMedia } from "../../apis/aboutApi";

const thumbnails = [
  {
    img: "/images/thumbnails/1.png",
  },
  {
    img: "/images/thumbnails/2.png",
  },
  {
    img: "/images/thumbnails/3.png",
  },
  {
    img: "/images/thumbnails/4.png",
  },
  {
    img: "/images/thumbnails/5.png",
  },
  {
    img: "/images/thumbnails/6.png",
  },
  {
    img: "/images/thumbnails/7.png",
  },
];

const About = () => {
  const { handleOpenSnackbar } = useSnackbar();
  const { data: socialMedia, isLoading: isLoadingSocialMedia } = useQuery(
    SOCIAL_MEDIA_QUERY_KEY(),
    getSocialMedia,
    {
      staleTime: Infinity,
      onError: (error) => {
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
    },
  );

  const {
    configs: {
      contactUsMapLink,
      contactUsEmail,
      contactUsPhone,
      contactUsAddress,
    },
    isLoadingConfigs,
  } = useGetAdminConfigs();

  return (
    <div className="flex w-full flex-col space-y-5 px-4 pb-10 pt-[25px] md:px-8 lg:space-y-10 lg:pt-[50px] xl:px-36">
      <div className="mx-auto flex w-full flex-col space-y-3 text-primary lg:w-[50%]">
        <h1 className="text-[24px] font-bold lg:text-[34px]">About Us</h1>
        <p className="text-[14px] font-light">
          At WAE, we are passionate about helping you create beautiful, functional, and
          personalized spaces. Our comprehensive service combines expert furniture
          consultancy with a versatile online customization platform, offering tailored
          solutions to meet your unique needs. Whether you're designing a custom piece
          or selecting from our exclusive ready-to-buy collection, our goal is to provide a
          seamless and enjoyable experience. With a commitment to quality,
          craftsmanship, and customer satisfaction, we strive to transform your vision
          into reality—so you can do it your WAE.
        </p>
      </div>
      <div className="-m-4 flex overflow-x-auto [-ms-overflow-style:none] [scrollbar-width:none] md:-m-8 xl:-m-36 [&::-webkit-scrollbar]:hidden">
        {thumbnails.map((item, index) => {
          return (
            <ImageContainer
              key={index}
              src={item?.img}
              containerClassName="aspect-square w-full min-w-[200px]"
              imgClassName="aspect-square w-full"
            />
          );
        })}
      </div>

      <div className="m-auto flex w-full flex-col space-y-5 text-primary lg:space-y-10">
        {/* <div className="flex flex-col space-y-3">
          <h1 className="text-[24px] font-bold lg:text-[34px]">Our Team</h1>
          <div className="grid gap-14 lg:grid-cols-2">
            {isLoadingTeamMembers
              ? Array.from({ length: 4 }).map((_, index) => {
                  return (
                    <div
                      key={index}
                      className="flex items-center gap-5 lg:gap-10"
                    >
                      <Skeleton className="aspect-square w-full max-w-[120px] rounded-full" />
                      <div className="flex w-full flex-col space-y-2">
                        <Skeleton width={100} height={20} />
                        <Skeleton width={150} height={20} />
                        <Skeleton width={200} height={20} />
                      </div>
                    </div>
                  );
                })
              : teamMembers?.map((member, index) => {
                  return (
                    <div
                      key={index}
                      className="flex items-center gap-5 lg:gap-10"
                    >
                      <img
                        src={member?.image}
                        className="aspect-square w-full max-w-[120px] rounded-full"
                      />
                      <div className="flex flex-col space-y-1">
                        <span className="text-[24px] font-bold">
                          {member?.name}
                        </span>
                        <span className="text-[18px]">{member?.title}</span>
                        <span className="text-[14px] font-light">
                          {member?.description}
                        </span>
                      </div>
                    </div>
                  );
                })}
          </div>
          
        </div> */}
        <div className="mx-auto flex w-full flex-col space-y-3 text-primary lg:w-[50%]">
          <h1 className="text-[24px] font-bold lg:text-[34px]">Our Team</h1>
          <p className="text-[14px] font-light">
            Our team is here to make your furniture dreams come true. We work hard to
            ensure a seamless and enjoyable experience. With a blend of skilled designers
            and expert consultants, we’re dedicated to delivering personalized, top-notch service.
          </p>
        </div>
        <div className="flex flex-col space-y-3">
          <div className="text-[24px] font-bold lg:text-[34px]">Contact Us</div>
          <div className="flex flex-col space-y-3 lg:flex-row lg:space-x-2 lg:space-y-0">
            {contactUsMapLink && (
              <iframe
                src={contactUsMapLink}
                allowFullScreen
                className="h-52 w-full lg:w-[24.25%]"
              />
            )}
            <div className="flex w-full flex-col space-y-3 lg:w-2/3 lg:justify-between">
              {contactUsEmail && (
                <div className="flex flex-col space-y-2">
                  <div className="text-[14px] font-medium">Email</div>
                  {isLoadingConfigs ? (
                    <Skeleton height={20} width={300} />
                  ) : (
                    <div className="text-[12px] opacity-70">
                      {contactUsEmail}
                    </div>
                  )}
                </div>
              )}
              {contactUsPhone && (
                <div className="flex flex-col space-y-2">
                  <div className="text-[14px] font-medium">Number</div>
                  {isLoadingConfigs ? (
                    <Skeleton height={20} width={300} />
                  ) : (
                    <div className="text-[12px] opacity-70">
                      {contactUsPhone}
                    </div>
                  )}
                </div>
              )}
              {contactUsAddress && (
                <div className="flex flex-col space-y-2">
                  <div className="text-[14px] font-medium">Location</div>
                  {isLoadingConfigs ? (
                    <Skeleton height={20} width={300} />
                  ) : (
                    <div className="text-[12px] opacity-70">
                      {contactUsAddress}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {!!socialMedia?.length && (
          <div className="flex items-center space-x-5 lg:space-x-10">
            <span className="flex-1 border-b border-secondary" />
            {socialMedia?.map(({ name, icon, url }) => {
              return (
                <NavLink key={name} to={url}>
                  <SvgIcon
                    src={icon}
                    className={
                      "h-[30px] w-[30px] shrink-0 cursor-pointer text-primary"
                    }
                  />
                </NavLink>
              );
            })}
            <span className="flex-1 border-b border-secondary" />
          </div>
        )}
      </div>
    </div>
  );
};

export default About;
