import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

import HEADER_ROUTES from "../../constants/headerRoutes";
import ROUTES from "../../constants/routes";
import useUser from "../../hooks/useUser";
import useAuth from "../../hooks/useAuth";
import MainButton from "../customButtons/MainButton";
import { arePathsEqual } from "../../utils/urls";

function SideBar({ isOpen, setIsOpen, innerRef }) {
  const currentPath = useLocation().pathname;
  const { isLoggedIn } = useUser();
  const { logOut, logOutLoading } = useAuth({
    onLogOut: () => {
      setIsOpen(false);
    },
  });

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  return (
    <aside
      className={`fixed left-0 top-16 z-40 flex h-[calc(100dvh-64px)] w-64 flex-col items-center bg-white py-8 shadow-sm transition-transform duration-300 ease-in lg:hidden ${
        !isOpen && "-translate-x-full"
      }`}
      ref={innerRef}
    >
      <div className="flex w-full flex-1 flex-col space-y-5 overflow-y-auto">
        {HEADER_ROUTES.map((route, index) => (
          <div key={index} className="flex space-x-3">
            <span
              className={`h-full w-2 rounded-r bg-highlight ${
                arePathsEqual(currentPath, route.path)
                  ? "opacity-100"
                  : "opacity-0"
              }`}
            />
            <NavLink
              key={route.path}
              to={route.path}
              onClick={() => {
                setIsOpen(false);
              }}
              className={`w-full text-[18px] ${
                arePathsEqual(currentPath, route.path)
                  ? "font-bold text-highlight"
                  : "text-primary"
              }`}
            >
              {route.label}
            </NavLink>
          </div>
        ))}

        <div className="flex space-x-3">
          <span
            className={`h-full w-2 rounded-r bg-highlight ${
              arePathsEqual(currentPath, ROUTES.CART.path)
                ? "opacity-100"
                : "opacity-0"
            }`}
          />
          <NavLink
            to={ROUTES.CART.path}
            onClick={() => {
              setIsOpen(false);
            }}
            className={`w-full text-[18px] ${
              arePathsEqual(currentPath, ROUTES.CART.path)
                ? "font-bold text-highlight"
                : "text-primary"
            }`}
          >
            {"Cart"}
          </NavLink>
        </div>

        {isLoggedIn && (
          <div className="mx-auto !mt-auto flex w-[90%] flex-col items-center space-y-4">
            <span className="w-full border border-softGray"></span>
            <MainButton
              onClick={logOut}
              isLoading={logOutLoading}
              className={"w-[90%]"}
            >
              Logout
            </MainButton>
          </div>
        )}
      </div>
    </aside>
  );
}

export default SideBar;
