import { useMemo } from "react";
import { BsDot } from "react-icons/bs";

import PageWrapper from "../../components/pageWrapper";
import Loader from "../../components/loader";
import useGetAdminConfigs from "../../hooks/useGetAdminConfigs";
import WAE_POLICIES from "../../constants/termsOfServiceData";
import {
  parseAndRenderTextWithLinks,
  replacePlaceholders,
} from "../../utils/parser";

function TermsOfService() {
  const { configs, isLoadingConfigs } = useGetAdminConfigs();
  const policies = useMemo(() => WAE_POLICIES, []);

  const replacements = {
    phone: configs.contactUsPhone,
    email: configs.contactUsEmail,
  };

  return (
    <PageWrapper>
      {isLoadingConfigs ? (
        <Loader className={"min-h-[400px]"} />
      ) : (
        <div className="flex flex-col space-y-3 text-primary lg:space-y-5">
          <h1 className="text-[24px] font-bold lg:text-[34px]">
            Terms of Service
          </h1>

          {policies.map((policy, index) => (
            <section key={index} className="flex flex-col space-y-3 pl-3">
              <h2 className="text-[20px] font-medium lg:text-[24px]">
                {policy.title}
              </h2>
              <RenderContent
                content={policy.description}
                replacements={replacements}
              />
              <RenderList
                items={policy.bulletPoints}
                replacements={replacements}
              />
              <RenderContent
                content={policy.note}
                replacements={replacements}
              />

              {policy.subSections?.map((subSection, subIndex) => (
                <div key={subIndex}>
                  <h3 className="lg:text-[20px] text-[16px] font-medium">
                    {subSection.title}
                  </h3>
                  <RenderContent
                    content={subSection.description}
                    replacements={replacements}
                  />
                  <RenderList
                    items={subSection.bulletPoints}
                    replacements={replacements}
                  />
                  <RenderContent
                    content={subSection.note}
                    replacements={replacements}
                  />
                </div>
              ))}
            </section>
          ))}
        </div>
      )}
    </PageWrapper>
  );
}

function RenderContent({ content, replacements }) {
  return content ? (
    <p className="whitespace-pre-line pl-3">
      {parseAndRenderTextWithLinks(replacePlaceholders(content, replacements))}
    </p>
  ) : null;
}

function RenderList({ items, replacements }) {
  return items && items.length > 0 ? (
    <ul className="pl-3">
      {items.map((item, index) => (
        <div key={index} className="flex space-x-1">
          <BsDot className="mt-[5px] shrink-0" />
          <li className="whitespace-pre-line">
            {parseAndRenderTextWithLinks(
              replacePlaceholders(item, replacements)
            )}
          </li>
        </div>
      ))}
    </ul>
  ) : null;
}

export default TermsOfService;
