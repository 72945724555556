const WAE_POLICIES = [
  {
    description: `Welcome to WAE – a platform dedicated to providing a seamless and flexible way to design furniture that fits your lifestyle and personal taste. With a wide range of styles, materials, colors, and fabrics, paired with expert design support, we make it easy for you to create pieces that feel uniquely yours. Whether you’re updating a single room or reimagining your entire space, WAE is here to help you bring your visions to life with comfort, quality, and ease.
      
      These Terms of Use apply to this website and its associated mobile services (collectively, the “Site”). In these Terms of Use, “we” or “us” refers to WAE. We may provide additional or separate terms of use that apply specifically to certain subdomains of the Site, programs, or activities.`,
    subSections: [
      {
        title: 'Website Policy',
        description: `We provide access to the Site and the products and services available on it under these Terms of Use. By using the Site, you agree to comply with these Terms of Use, including any updates we may make over time. The latest version of these Terms of Use can always be reviewed by clicking the Terms of Use link in the footer of any page on the Site.
          
          Please read these Terms of Use thoroughly, as they outline our rights regarding the Site as well as your rights and responsibilities when using it. If you do not agree to these Terms of Use, you should refrain from using the Site.`,
      },
      {
        title: 'Limited Use of the Site',
        description: `You are granted a restricted, revocable, non-exclusive, non-transferable, and non-sublicensable license to access and use the Site through a standard web browser or mobile device. This access is solely for viewing content and using the Site’s features in compliance with these Terms of Use. Any unauthorized use of the Site violates these Terms. All content on the Site—including text, graphics, images, videos, and data—is owned or licensed by us and protected under copyright, trademark, and other proprietary laws. The arrangement of this content is the exclusive property of Ashley Global Retail, LLC and its affiliates. All intellectual property rights are reserved.`,
      },
      {
        title: 'Prohibited Uses',
        description: `You may not use the Site improperly or for prohibited purposes, including but not limited to:`,
        bulletPoints: [
          'Engaging in commercial activities using the Site or its content.',
          'Using unauthorized tools, bots, or scraping mechanisms to interact with the Site.',
          'Framing, mirroring, or altering Site content without prior consent.',
          'Collecting or storing data, images, or other content for unauthorized purposes.',
          'Misrepresenting your identity or falsely affiliating with others.',
          'Conducting fraudulent activities or attempting to breach Site security.',
          'Using the Site to harass, stalk, or violate others’ legal rights.',
          'Modifying, reverse-engineering, or dismantling any part of the Site.',
          'Removing copyright or trademark notices from the Site or its materials.',
        ],
      },
      {
        title: 'Account Responsibility',
        description: `If you create an account, you are responsible for its security and all actions performed through it. Keep your password confidential, do not share access, and immediately notify us if your account is compromised. If you suspect unauthorized access, change your password promptly.`,
      },
      {
        title: 'Changes to the Site',
        description: `We may update or discontinue any part of the Site, its content, or the products and services it offers at our discretion, without prior notice. This includes altering access, removing content, or modifying these Terms of Use.`,
      },
      {
        title: 'Access Restrictions',
        description:
          'You must be at least 13 years old to use the Site. We reserve the right to restrict or deny access to anyone for any reason, at our sole discretion.',
      },
      {
        title: 'Notifications',
        description:
          'We may send you important updates about the Site or its services via email or other communication methods. Ensure your account information is current to manage the type of messages you receive.',
      },
      {
        title: 'Privacy Policy',
        description: `Our data collection and usage practices are outlined in our Privacy Policy, which is incorporated into these Terms. Review it carefully to understand how we handle your personal information.`,
      },
    ],
  },
  {
    title: 'Privacy Policy',
    description: `The terms used in this Privacy Policy have the same meanings as those in our Terms and Conditions, which are accessible at <a href="https://yourwae.com">yourwae.com</a>, unless otherwise defined here.`,
    subSections: [
      {
        title: 'Information Collection and Use',
        description:
          'WAE does not use any third-party services that collect information to personally identify you while you use our platform directly.',
        bulletPoints: [],
      },
      {
        title: 'Permissions Used by the Platform',
        description:
          'Internet Access: Required to load content from our servers.',
      },
      {
        title: 'Log Data',
        description: `When you use our services, we may collect certain data in case of an error, referred to as "Log Data." This may include information such as your device's Internet Protocol (IP) address, device name, operating system version, app configuration during usage, the time and date of your use, and other diagnostic details.`,
      },
      {
        title: 'Cookies',
        description: `Cookies are small files that are commonly used as unique identifiers. They are sent to your browser from websites you visit and stored on your device.
          While WAE does not explicitly use cookies, third-party code and libraries integrated into the platform may use cookies to collect information and enhance their services. You can choose to accept or refuse these cookies. However, refusing cookies may limit your access to certain features of the platform.`,
      },
      {
        title: 'Service Providers',
        description:
          'We may work with third-party companies or individuals for the following purposes:',
        bulletPoints: [
          'To facilitate our services.',
          'To provide services on our behalf.',
          'To perform service-related functions.',
          'To analyze how our platform is used.',
        ],
        note: 'These third parties may have access to your personal information but are obligated not to use it for any purpose other than the tasks assigned to them.',
      },
      {
        title: 'Security',
        description: `We take reasonable precautions to safeguard your personal information. However, no method of online transmission or electronic storage is entirely secure. While we strive to protect your data, we cannot guarantee its absolute security.`,
      },
      {
        title: 'Links to Other Websites',
        description: `Our platform may contain links to third-party websites. If you click on these links, you will be directed to external sites not operated by us. We strongly recommend reviewing the privacy policies of these websites. WAE is not responsible for the content, privacy practices, or services of third-party sites.`,
      },
      {
        title: 'Children’s Privacy',
        description: `WAE does not target or knowingly collect information from individuals under the age of 13. If we discover that a child under 13 has provided personal information, we will delete it immediately. Parents or guardians who become aware of such instances should contact us to resolve the matter promptly.`,
      },
      {
        title: 'Changes to This Privacy Policy',
        description: `We may update this Privacy Policy periodically. You are encouraged to review it regularly for any changes. Updates will be posted on this page and will take effect immediately upon posting.`,
      },
    ],
  },

  {
    title: 'Delivery Policy',
    description: `All orders are subject to the availability of products. If an item in your order is out of stock, we will ship the available items immediately and send the remaining items once they are back in stock.
      
      Shipping costs are non-refundable and are determined based on the weight of your items and your delivery location. Delivery dates may vary due to factors such as courier practices, your location, the chosen delivery method, and the number of items in your order. Additionally, your order may arrive in multiple shipments.
      
      Extra shipping fees may apply depending on your location and the size of your order.`,
  },
  {
    title: 'Refund Policy',
    description: `At WAE, we are committed to delivering you the very best shopping experience. In case we sent you the wrong product, or the product is faulty, or if you changed your mind after paying, WAE will be happy to accept returns free of charge, provided that:`,
    bulletPoints: [
      'Products are returned in the original packaging.',
      'The package was never opened, i.e. is sealed (*).',
      'The product shows no sign of usage (*).',
      'The stamped invoice is attached.',
    ],
    note: `(*) Condition doesn’t apply for faulty (defective) products
  
  You have up to 30 days to return it. The 30-day policy is not inclusive of custom designs.
  
  Please note that items are not returnable in the case of:
      1. Damage caused by misuse or signs of wear and tear
      2. The product has been used, purposely altered, or assembled
      3. Products you wish to exchange for a different color or size. (We currently do not offer an exchange service which means that you will need to return the product rst and purchase what you wish instead).
      4. Please return your non-furniture products in their original packaging, within 30 days of delivery. Original invoice must be included as well as any accessories, promotional gifts and warranty cards that came with your order`,
  },
  {
    title: 'Furniture Return Policy and Delivery Deferment',
    subSections: [
      {
        title: 'Return Policy',
        description: `You have 72 hours after you place your furniture order of full flexibility to cancel the order or to make any changes in style, color, or dimensions. In the case of cancellation within 72 hours, you will receive a full refund of your payment.
  
          Within 7 days of placing your order, if you’d like to cancel it you will receive a 50% refund of your payment  
  
          After 7 days of placing your order, you cannot amend or cancel the order.
          
          Furniture products are eligible only for replacement exclusively in the case of manufacturing defects.`,
      },
      {
        title: 'Delivery Deferment',
        description: `You can request to receive your furniture products up to 5 months after you purchase the order. Please note that you need to pay 100% of the payment to be able to receive the order at a later date other than the original delivery date.`,
      },
    ],
  },
  {
    title: 'ALL TERMS AND CONDITIONS APPLY',
    description: `This Privacy Policy governs the manner in which WAE collects, uses, maintains, and discloses information collected from users (each, a “User”) of the <a href="https://yourwae.com">www.yourwae.com</a> website (“Site”). This privacy policy applies to the Site and all products and services offered by WAE.`,
    bulletPoints: [
      'Any dispute or claim arising out of or in connection with this website shall be governed and construed in accordance with the laws of EGY.',
      'Egypt is our country of domicile.',
      'Minors under the age of 18 are prohibited from registering as a User of this website and are not allowed to transact or use the website.',
      'If you make a payment for our products or services on our website, the details you are asked to submit will be provided directly to our payment provider via a secured connection.',
      'The cardholder must retain a copy of transaction records and Merchant policies and rules.',
    ],
  },
  {
    title: 'Personal Identification Information',
    description: `We may collect personal identification information from Users in various ways, including, but not limited to, when Users visit our site, register on the site, place an order, ll out a form, subscribe to the newsletter, and in connection with other activities, services, features, or resources we make available on our Site. Users may be asked for, as appropriate, their name, email address, or phone number.
      Users may, however, visit our Site anonymously.
      We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personal identification information, except that it may prevent them from engaging in certain Site-related activities.`,
  },
  {
    title: 'Non-Personal Identification Information',
    description:
      'We may collect non-personal identification information about Users whenever they interact with our Site. This may include the browser name, type of computer, and technical information about how Users connect to our Site, such as the operating system, Internet service providers, and similar information.',
  },
  {
    title: 'Web Browser Cookies',
    description: `Our Site may use “cookies” to enhance the User experience. A User’s web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. Users may choose to set their web browser to refuse cookies or alert them when cookies are sent. If they do so, note that some parts of the Site may not function properly.`,
  },
  {
    title: 'How We Use Collected Information',
    description:
      'WAE collects and uses Users’ personal information for the following purposes:',
    bulletPoints: [
      'To improve customer service: Your information helps us respond to your requests and support needs more effectively.',
      'To personalize user experience: We use information in aggregate to understand how Users use our services and resources.',
      'To improve our Site: Feedback helps us rene our offerings.',
      'To process transactions: Information provided during orders is used solely for order fulfillment.',
      'To administer promotions, surveys, or Site features: We may send Users information they agreed to receive about topics of interest.',
      'To send periodic emails: Emails are used for order updates, responses to inquiries, and company news or promotions.',
    ],
    note: 'Users can unsubscribe from mailing lists by following instructions included in each email or contacting us through the Site.',
  },
  {
    title: 'How We Protect Your Information',
    description:
      'We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information. Our Site complies with PCI vulnerability standards for a secure environment.',
  },
  {
    title: 'Delivery / Shipment',
    description: `WAE will NOT deal with or provide services or products to any OFAC (Office of Foreign Assets Control) sanctioned countries in accordance with Egyptian law.
      Multiple shipments/deliveries may result in multiple postings to the cardholder’s monthly statement.`,
  },
  {
    title: 'Changes to This Privacy Policy',
    description: `WAE reserves the right to update this Privacy Policy at any time. Updates will be posted on our main page, and the updated date will be revised. Users are encouraged to review this page periodically to stay informed about how we protect collected information.`,
  },
  {
    title: 'Your Acceptance of These Terms',
    description: `By using this Site, you signify your acceptance of this policy and terms of service. If you do not agree, please do not use the Site. Continued use of the Site following changes will be deemed acceptance of those changes.`,
  },
  {
    title: 'Return Policy',
    description:
      'WAE is committed to delivering the best shopping experience. Returns are accepted free of charge if (excluding custom design products):',
    bulletPoints: [
      'Products are returned in original packaging.',
      'The package is unopened/sealed (*)',
      'The product shows no signs of use (*).',
      'The stamped invoice is attached.',
    ],
    note: '(*) Conditions do not apply to defective products.',
  },
  {
    title: 'General Guidelines:',

    bulletPoints: [
      'Returns must be made within 30 days of delivery, except for for custom designed products.',
    ],
  },
  {
    title: 'Products are not eligible for return in cases of:',

    bulletPoints: [
      'Misuse or wear and tear.',
      'Alteration or assembly by the customer.',
      'Exchange requests for size or color changes (as exchanges require a return and repurchase).',
    ],
  },
  {
    title: 'Furniture-Specific Return Policy',
    bulletPoints: [
      'Changes or cancellations can be made within 72 hours of placing the order for a full refund.',
      'Cancellations within 7 days are eligible for a 50% refund.',
      'No cancellations or changes are allowed after 7 days.',
      'Replacement is available only for manufacturing defects.',
    ],
  },
  {
    title: 'Delivery Deferment:',
    bulletPoints: [
      'Furniture products can be deferred up to 3 months with full payment made in advance.',
    ],
  },
  {
    title: 'Refund & Cancellation Policy',
    description:
      'Refunds are processed through the original payment method. A 3% handling fee applies to canceled credit card orders.',
    bulletPoints: [
      'Call customer service for return guidance.',
      `Approved returns can result in:
  ◦ An exchange.
  ◦ A refund via WAE Voucher or bank transfer.`,
    ],
  },
  {
    title: 'Contact Information',
    description: 'For issues or questions, please contact WAE at:',
    bulletPoints: [
      'Website: <a href="https://yourwae.com/contact-us">www.yourwae.com</a>',
      'Phone: {phone}',
      'Email: {email}',
    ],
  },
];

export default WAE_POLICIES;
