export class Route {
  constructor(label, path) {
    this.label = label;
    this.path = path;
  }
}

const HOME = new Route("Home", "/");
const SIGN_UP = new Route("Sign Up", "/auth/register");
const SIGN_IN = new Route("Sign In", "/auth/login");
const STYLE = new Route("Find Your Style", "/style");
const CUSTOM_DESIGN = new Route("Shop", "/custom-design");
const CONSULTANT = new Route("Book a Consultant", "/book-a-consultant");
const ABOUT_US = new Route("About", "/about-us");
const FAQ = new Route("FAQ", "/faq");
const CONTACT_US = new Route("Contact Us", "/contact-us");
const TERMS_OF_SERVICE = new Route("Terms of Service", "/terms-policies");
const LIMITED_COLLECTION = new Route("Limited Collection", "/limited-collection");
const SAMPLE = new Route("Samples", "/samples");
const DESIGNS = new Route("Designs", "/custom-design");
const FBXBASEURL = new Route("FBX Base URL", "./fbx/scaled/FBX/");
const PRODUCT = new Route("Products", "/limited-collection/products");
const CART = new Route("Cart", "/cart");
const CHECKOUT = new Route("Checkout", "/checkout");
const PROFILE = new Route("Profile", "/profile");
const FORGET_PASSWORD = new Route("Forget Password", "/auth/forget-password");
const PAYMENT_STATUS = new Route("Payment Status", "/payment-status");

export const BOOKING_CONSULTANT_PARAMS = {
  CONSULTATION_TYPE: "consultation-type",
  BOOKING_DETAILS: "booking-details",
};

export const CONTACT_US_PARAM = "contact-us";

const ROUTES = {
  HOME,
  SIGN_UP,
  SIGN_IN,
  STYLE,
  CUSTOM_DESIGN,
  CONSULTANT,
  ABOUT_US,
  FAQ,
  CONTACT_US,
  TERMS_OF_SERVICE,
  LIMITED_COLLECTION,
  SAMPLE,
  DESIGNS,
  FBXBASEURL,
  PRODUCT,
  CART,
  CHECKOUT,
  PROFILE,
  FORGET_PASSWORD,
  PAYMENT_STATUS,
};

export default ROUTES;
