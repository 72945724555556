import React from "react";
import { useQuery } from "react-query";
import { findFullDesignImage } from "../apis/designsApi";


const useGetFullImage = (designImage) => {

    const { data: image_name= null, } = useQuery(
        ["find-full-design-image", designImage],
        () => findFullDesignImage(designImage),
        { 
            enabled: !!designImage,
            staleTime: Infinity,
            // onSuccess: (data) => {
            //   queryClient.removeQueries({
            //     predicate: (query) => query.queryKey[0].startsWith("find-full-design-image") && query.queryKey[1] !== designImage,
            //   });
            // }
        },
    );

    return {image_name,};

};

export default useGetFullImage;