import { useMemo, useContext, useLayoutEffect } from "react";

import FilterContext from "../contexts/FilterProvider";

const DEFAULT_OPTIONS = [];

function useFilter(options = DEFAULT_OPTIONS, currentStepTitle) {
  const {
    setFilters,
    selectedFilters,
    selectedFiltersDispatch,
    RESET_SELECTED_FILTERS,
  } = useContext(FilterContext);

  useLayoutEffect(() => {
    selectedFiltersDispatch({ type: RESET_SELECTED_FILTERS });

    let colorsFiltersSet = new Set();
    let materialsFiltersSet = new Set();
    let stylesFiltersSet = new Set();
    let dimensionsFiltersSet = new Set();

    options?.forEach((option) => {
      option?.colors?.forEach((color) => {
        colorsFiltersSet.add(color);
      });

      option?.fabrics?.forEach((material) => {
        materialsFiltersSet.add(material);
      });

      option?.styles?.forEach((style) => {
        stylesFiltersSet.add(style);
      });

      option?.dimension_tags?.forEach((dimension) => {
        dimensionsFiltersSet.add(dimension);
      });

    });

    const colorsFilters = Array.from(colorsFiltersSet);
    const materialsFilters = Array.from(materialsFiltersSet);
    const stylesFilters = Array.from(stylesFiltersSet);
    const dimensionsFilters = Array.from(dimensionsFiltersSet);

    setFilters({
      colorsFilters,
      materialsFilters,
      stylesFilters,
      dimensionsFilters,
    });
  }, [currentStepTitle]);

  const filteredOptions = useMemo(() => {
    return filterOptions(options, selectedFilters);
  }, [selectedFilters, options]);

  return {
    filteredOptions,
  };
}

export default useFilter;

function filterOptions(options, selectedFilters) {
  const {
    selectedColorsFilters,
    selectedMaterialsFilters,
    selectedStylesFilters,
    selectedDimensionsFilters,
  } = selectedFilters;

  const colorPatterns = selectedColorsFilters.map(
    (filter) => new RegExp(filter, "i"),
  );
  const materialPatterns = selectedMaterialsFilters.map(
    (filter) => new RegExp(filter, "i"),
  );
  const stylePatterns = selectedStylesFilters.map(
    (filter) => new RegExp(filter, "i"),
  );
  const dimensionPatterns = selectedDimensionsFilters.map(
    (filter) => new RegExp(filter, "i"),
  );

  const filteredOptions = options?.filter((option) => {
    const hasMatchingColors =
      colorPatterns.length === 0 ||
      colorPatterns.some((pattern) =>
        option?.colors?.some((color) => pattern.test(color)),
      );

    const hasMatchingMaterials =
      materialPatterns.length === 0 ||
      materialPatterns.some((pattern) =>
        option?.fabrics?.some((material) => pattern.test(material)),
      );
    const hasMatchingStyles =
      stylePatterns.length === 0 ||
      stylePatterns.some((pattern) =>
        option?.styles?.some((style) => pattern.test(style)),
      );
    const hasMatchingDimensions =
      dimensionPatterns.length === 0 ||
      dimensionPatterns.some((pattern) =>
        option?.dimension_tags?.some((dimension) => pattern.test(dimension)),
      );

    return (
      hasMatchingColors &&
      hasMatchingMaterials &&
      hasMatchingStyles &&
      hasMatchingDimensions
    );
  });

  return filteredOptions || [];
}
