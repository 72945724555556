import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ROUTES from "../../constants/routes";
import OutlineButton from "../customButtons/OutlineButton";
import MainButton from "../customButtons/MainButton";
import useUser from "../../hooks/useUser";
import useAuth from "../../hooks/useAuth";
import SvgIcon from "../svgIcon";
import { USER_ICON_URL } from "../../constants/iconsUrls";

function ProfileMenu() {
  const menuRef = useRef();
  const [showMenu, setShowMenu] = useState(false);
  const { logOut, logOutLoading } = useAuth();
  const { isLoggedIn } = useUser();
  const navigate = useNavigate();
  const currentPath = useLocation().pathname;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div
      ref={menuRef}
      className={`relative text-secondary hover:text-primary ${
        currentPath === ROUTES.PROFILE.path && "!text-highlight"
      }`}
    >
      <SvgIcon
        src={USER_ICON_URL}
        className={`hidden h-8 w-8 cursor-pointer lg:block ${
          currentPath === ROUTES.PROFILE.path
            ? "text-highlight"
            : "text-secondary hover:text-primary"
        }`}
        onClick={() => setShowMenu(true)}
      />
      <SvgIcon
        src={USER_ICON_URL}
        className={`h-8 w-8 cursor-pointer lg:hidden ${
          currentPath === ROUTES.PROFILE.path
            ? "text-highlight"
            : "text-secondary hover:text-primary"
        }`}
        onClick={() => navigate(isLoggedIn ? ROUTES.PROFILE.path : ROUTES.SIGN_IN.path)}
      />
      <span
        className={`absolute right-2 top-9 z-10 h-2 w-2 -rotate-45 border-r border-t border-softGray bg-white ${
          showMenu ? "block" : "hidden"
        }`}
      />
      <div
        className={`absolute -right-3 top-10 flex flex-col space-y-2 rounded border border-softGray shadow bg-white p-3 ${
          showMenu ? "block" : "hidden"
        }`}
      >
        <OutlineButton
          className="z-20 py-[3px]"
          onClick={() => {
            navigate(isLoggedIn ? ROUTES.PROFILE.path : ROUTES.SIGN_IN.path);
            setShowMenu(false);
          }}
        >
          {isLoggedIn ? "Profile" : "Sign in"}
        </OutlineButton>
        <MainButton
          className="z-20 rounded py-1"
          isLoading={logOutLoading}
          onClick={
            isLoggedIn
              ? logOut
              : () => {
                  navigate(ROUTES.SIGN_UP.path);
                  setShowMenu(false);
                }
          }
        >
          {isLoggedIn ? "Log out" : "Sign up"}
        </MainButton>
      </div>
    </div>
  );
}

export default ProfileMenu;
