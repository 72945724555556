import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AddToCartButton from "../../../components/customButtons/AddToCartButton";
import BookmarkButton from "../../../components/customButtons/BookmarkButton";
import MainButton from "../../../components/customButtons/MainButton";
import useUser from "../../../hooks/useUser";
import useAddCartItem from "../../../hooks/useAddCartItem";
import useGetCart from "../../../hooks/useGetCart";
import useSnackbar from "../../../components/snackbar/hooks/useSnackbar";
import useGetAdminConfigs from "../../../hooks/useGetAdminConfigs";
import useGetFullImage from "../../../hooks/useGetFullImage";
import ROUTES from "../../../constants/routes";
import { PRICE_MODES } from "../../../constants/priceModes";
import { formatToCurrency } from "../../../utils/numbers";
import { CART_ITEMS_TYPES } from "../../../constants/cartItems";
import { updateDesign } from "../../../apis/designsApi";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { markAsUnchanged } from "../../../features/designSlice";
import { useQueryClient } from "react-query";
import { CART_ITEMS_QUERY_KEY } from "../../../queries-keys/cartItems";
import { getErrorMessage } from "../../../utils/errors";
import { CURRENCY } from "../../../constants/common";
import SvgIcon from "../../../components/svgIcon";
import { DELIVERY_ICON_URL } from "../../../constants/iconsUrls";

const DESIGNS_IMAGES_PATH = process.env.REACT_APP_DESIGNS_IMAGES_PATH

function DesignSummary() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const selectedLayout = useSelector((state) => state.design.layout);
  const elementsSelectedOptions = useSelector((state) => state.design.elements);
  const priceMode = useSelector((state) => state.design.priceMode);
  const totalPrice = useSelector((state) => state.design.totalPrice);
  const totalPriceRangePercentage = useSelector((state) => state.design.totalPriceRangePercentage);
  const design = useSelector((state) => state.design);
  const isDesignChanged = useSelector((state) => state.design.isChanged);
  const queryClient = useQueryClient();
  const { user } = useUser();
  const { cartItems } = useGetCart();
  const { handleOpenSnackbar } = useSnackbar();

  const {
    configs: { shippingNotes, designExpectedDeliveryDuration },
  } = useGetAdminConfigs();

  const { addCartItemMutation, addCartItemMutationAsync } = useAddCartItem();
  const { image_name } = useGetFullImage(design?.image);

  const { mutate: updateDesignMutation, isLoading: isSavingDesign } = useMutation(
    (updatedData) => updateDesign(design?.id, updatedData),
    {
      onError: (error) => {
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
      onSuccess: () => {
        dispatch(markAsUnchanged());
        queryClient.invalidateQueries(CART_ITEMS_QUERY_KEY(user?.id));
        handleOpenSnackbar("Your design has been saved!", "success");
      },
    }
  );

  const { mutate: updateAndAddDesignToCartMutation, isLoading: isSavingAndAddingDesignToCart } =
    useMutation(
      async (updatedData) => {
        await updateDesign(design?.id, updatedData);

        dispatch(markAsUnchanged());
        queryClient.invalidateQueries(CART_ITEMS_QUERY_KEY(user?.id));

        return await addCartItemMutationAsync({
          design: design.id,
          type: CART_ITEMS_TYPES.DESIGN,
          quantity: count,
        });
      },
      {
        onError: (error) => {
          handleOpenSnackbar(getErrorMessage(error), "error");
        },
        onSuccess: () => {
          handleOpenSnackbar("Your design has been added to your cart!", "success");
        },
      }
    );

  const handleDesignSaving = () => {
    if (!isDesignChanged) {
      return;
    }

    const { layout, elements, totalPrice: price } = design;
    updateDesignMutation({
      layout_body: layout.layout_body,
      layout_size: layout.layout_size,
      elements,
      image: String(DESIGNS_IMAGES_PATH) + "/" + image_name + ".png",
      price,
    });
  };

  const handleAddToCart = () => {
    const found = cartItems.some(
      (cartItem) =>
        cartItem?.type === CART_ITEMS_TYPES.DESIGN && cartItem[cartItem?.type]?.id === design.id
    );

    if (found) {
      return handleDesignSaving();
    }

    if (isDesignChanged) {
      const { layout, elements, totalPrice: price } = design;

      return updateAndAddDesignToCartMutation({
        layout_body: layout.layout_body,
        layout_size: layout.layout_size,
        elements,
        image: String(DESIGNS_IMAGES_PATH) + "/" + image_name + ".png",
        price,
      });
    }

    addCartItemMutation({
      design: design.id,
      type: CART_ITEMS_TYPES.DESIGN,
      quantity: count,
    });
  };

  return (
    <div className="flex flex-col space-y-5 text-primary">
      <div className="flex items-center justify-between">
        <span className="text-[18px] font-bold">Summary</span>
        {/* <div className="flex items-center space-x-2">
          <FiMinusSquare
            className="cursor-pointer text-[25px] text-highlight"
            onClick={() => setCount((prev) => Math.max(1, prev - 1))}
          />
          <span className="font-medium">{count}</span>
          <FiPlusSquare
            className="cursor-pointer text-[25px] text-highlight"
            onClick={() => setCount((prev) => prev + 1)}
          />
        </div> */}
      </div>
      <div className="flex flex-col space-y-3">
        {!!selectedLayout.layout_body && !!selectedLayout.layout_size && (
          <div className="flex flex-col space-y-1">
            <span className="text-[14px] font-bold">Layout</span>
            <div className="flex items-center justify-between">
              <span>
                {selectedLayout.layout_size?.name 
                + (selectedLayout.layout_size?.dimensions ? " (" + selectedLayout.layout_size?.dimensions + ")" : "")}
              </span>
              <span>
                {formatToCurrency(
                  selectedLayout.layout_size?.price,
                  CURRENCY,
                  selectedLayout.layout_size?.range_price_percentage,
                  priceMode
                )}
              </span>
            </div>
            <div className="flex items-center justify-between">
              <span>{selectedLayout.layout_body?.name}</span>
              <span>
                {formatToCurrency(
                  selectedLayout.layout_body?.price,
                  CURRENCY,
                  selectedLayout.layout_body?.range_price_percentage,
                  priceMode
                )}
              </span>
            </div>
          </div>
        )}
        {Object.keys(elementsSelectedOptions).map((elementId, index) => {
          const { elementName, shape, material = {} } = elementsSelectedOptions[elementId];
          return (
            <React.Fragment key={index}>
              <hr />
              <div className="flex flex-col space-y-1">
                <span className="text-[14px] font-bold">{elementName}</span>
                <div className="flex items-center justify-between">
                  <span>{shape?.name}</span>
                  <span>
                    {formatToCurrency(
                      shape?.price,
                      CURRENCY,
                      shape?.range_price_percentage,
                      priceMode
                    )}
                  </span>
                </div>
                {Object.keys(material).map((material_name, index2) => {
                  return (
                    <div key={index2} className="flex items-center justify-between">
                      <span>{material[material_name]?.name}</span>
                      <span>
                        {formatToCurrency(
                          material[material_name]?.price,
                          CURRENCY,
                          material[material_name]?.range_price_percentage,
                          priceMode
                        )}
                      </span>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <div className="flex items-center justify-between text-primary">
        <span className="text-[18px] font-medium">Total Price</span>
        <span className="text-[32px] font-bold">
          {formatToCurrency(totalPrice, CURRENCY, totalPriceRangePercentage, priceMode)}
        </span>
      </div>
      <div className="flex justify-end space-x-4 pt-4">
        <BookmarkButton onClick={handleDesignSaving} disabled={!isDesignChanged} />
        <AddToCartButton onClick={handleAddToCart} className="w-full lg:w-[180px]" />
      </div>
      <div className="flex flex-col space-y-1">
        <div className="mx-auto flex items-center space-x-3">
          <SvgIcon src={DELIVERY_ICON_URL} className={'h-[40px] text-primary'}/>
          <span>Ready In {designExpectedDeliveryDuration} weeks</span>
        </div>
        <div className="flex flex-col">
          <span className="font-medium text-[14px]">Please Note:</span>
          <span className="text-[14px]">{shippingNotes}</span>
        </div>
      </div>
    </div>
  );
}

export default DesignSummary;
