import { Route } from "./routes";
import ROUTES from "./routes";

const FOOTER_ROUTES = [
  new Route("Home", ROUTES.HOME.path),
  new Route("Custom Design", ROUTES.CUSTOM_DESIGN.path),
  // new Route('Find Your Style', ROUTES.STYLE.path),
  new Route("Book a Consultant", ROUTES.CONSULTANT.path),
  new Route("About Us", ROUTES.ABOUT_US.path),
  new Route("FAQ", ROUTES.FAQ.path),
  new Route("Terms of Service", ROUTES.TERMS_OF_SERVICE.path),
  new Route("Contact Us", ROUTES.CONTACT_US.path),
];

export default FOOTER_ROUTES;
