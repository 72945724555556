import api from "./config/axiosWith401Redirect";
import Design from "../models/Design";

const DESIGNS_URL = "/designs";

export const getDesign = async (designId) => {
  const response = await api.get(`${DESIGNS_URL}/${designId}/`);
  return new Design(response.data);
};

export const createDesign = async (itemId) => {
  const response = await api.post(`${DESIGNS_URL}/`, { item: itemId });
  return new Design(response.data);
};

export const updateDesign = async (designId, updatedData) => {
  const response = await api.patch(`${DESIGNS_URL}/${designId}/`, updatedData);
  return new Design(response.data);
};

export const getUserDesigns = async () => {
  const response = await api.get(`${DESIGNS_URL}/user/`);
  return new Design(response.data);
};

export const deleteDesign = async (designId) => {
  const response = await api.delete(`${DESIGNS_URL}/${designId}/`);
  return response;
}

export const createCopiedDesign = async (copiedDesign) => {
  const response = await api.post(`${DESIGNS_URL}/`, {copied_design : copiedDesign});
  return new Design(response.data);
};

export const createDesignPricingRequest = async (designId) => {
  const response = await api.post(`${DESIGNS_URL}/${designId}/request_pricing/`);
  return response;
};

export const findFullDesignImage = async (image_name) => {
  const response = await api.post(`${DESIGNS_URL}/find-full-design-image/`, {image_name: image_name});
  return response.data?.image_name;
};