import { Route } from './routes';
import ROUTES from './routes';

const HEADER_ROUTES = [
  new Route("Custom Design", ROUTES.CUSTOM_DESIGN.path),
  // new Route("Find Your Style", ROUTES.STYLE.path),
  new Route("Book a Consultant", ROUTES.CONSULTANT.path),
  new Route("Limited Collection", ROUTES.LIMITED_COLLECTION.path),
  new Route("About Us", ROUTES.ABOUT_US.path),
  new Route("Order a Sample", ROUTES.SAMPLE.path),
];

export default HEADER_ROUTES;
