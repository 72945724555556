import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AddToCartButton from "../../components/customButtons/AddToCartButton";
import useAddCartItem from "../../hooks/useAddCartItem";
import useGetCart from "../../hooks/useGetCart";
import useUser from "../../hooks/useUser";
import ROUTES from "../../constants/routes";
import { CART_ITEMS_TYPES } from "../../constants/cartItems";
import { formatToCurrency } from "../../utils/numbers";
import { CURRENCY } from "../../constants/common";
const SamplesSelector = ({
  samples,
  currentSelectedSampleType,
  setCurrentSelectedSampleType,
  setCurrentViewedSample,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedSamples, setSelectedSamples] = useState([]);
  const { isLoggedIn, user } = useUser();
  const { addCartItemMutation } = useAddCartItem();
  const { cartItems } = useGetCart();
  const samplesTypes = Object.keys(samples || {});

  const handleSelectingSamplesType = (samplesType) => {
    setCurrentSelectedSampleType(samplesType);
    const sampleSubType = Object.keys(samples?.[samplesType] || {})[0];
    setCurrentViewedSample(samples?.[samplesType]?.[sampleSubType]?.[0]);
    setSelectedSamples([]);
  };

  const handelSelectingSample = (sample) => {
    const isSelected = !!selectedSamples.find((_sample) => sample?.id === _sample?.id);

    isSelected
      ? setSelectedSamples((prevValue) => prevValue.filter((_sample) => sample?.id !== _sample?.id))
      : setSelectedSamples((prevValue) => [...prevValue, { ...sample }]);
    setCurrentViewedSample(sample);
  };

  const handleHoverSample = (sample) => {
    setCurrentViewedSample(sample);
  };

  const handelAddToCart = () => {
    if (!isLoggedIn || !user) {
      return navigate(ROUTES.SIGN_IN.path, { state: { from: location } });
    }

    for (const sample of selectedSamples) {
      const found = cartItems.some(
        (cartItem) =>
          cartItem?.type === CART_ITEMS_TYPES.SAMPLE && cartItem[cartItem?.type]?.id === sample.id
      );

      if (!found) {
        addCartItemMutation({
          sample: sample.id,
          type: CART_ITEMS_TYPES.SAMPLE,
        });
      }
    }

    setSelectedSamples([]);
  };

  return (
    <div className="!mt-5 flex h-full w-full lg:w-1/2 flex-col space-y-3 lg:!mt-0">
      {Object.values(samples || {}).every((type) => Object.keys(type).length === 0) && (
        <span>No samples exists</span>
      )}
      <div className="flex">
        {samplesTypes?.map((type, index) => (
          <button
            key={index}
            className={`w-full px-3 py-1 first:rounded-l last:rounded-r lg:w-1/5 ${
              currentSelectedSampleType === type
                ? "bg-highlight text-white hover:bg-red-700"
                : "bg-gray-100 text-softGray hover:bg-gray-200"
            }
              `}
            onClick={() => handleSelectingSamplesType(type)}
          >
            {type}
          </button>
        ))}
      </div>
      <div className="h-[400px] space-y-2 overflow-auto pr-2">
        {Object.keys(samples?.[currentSelectedSampleType] || {})?.map((subType, index) => (
          <div key={index} className="flex flex-col space-y-1">
            <span>{subType}</span>
            <div className="grid grid-cols-[repeat(auto-fill,minmax(100px,1fr))] gap-2">
              {samples?.[currentSelectedSampleType]?.[subType]?.map((sample, index) => {
                const isSelected = !!selectedSamples.find((item) => sample?.id === item?.id);
                return (
                  <div className="w-full overflow-hidden" key={index}>
                    <img
                      src={sample.image}
                      alt={sample?.name}
                      onClick={
                        sample?.quantity ? () => handelSelectingSample(sample, subType) : () => {}
                      }
                      onMouseEnter={() => handleHoverSample(sample)}
                      className={`aspect-video w-full cursor-pointer rounded object-cover
                    ${isSelected && "scale-[80%] border-[3px] border-highlight"} ${
                        sample?.quantity === 0 && "!cursor-not-allowed opacity-50"
                      }
                    `}
                    />

                    <p
                      className={`pt-2 text-center ${
                        sample?.quantity === 0 && " line-through opacity-50"
                      }`}
                    >
                      {formatToCurrency(sample.price, CURRENCY)}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>

      <AddToCartButton
        disabled={!selectedSamples.length}
        className={`!mt-[2vh] ml-auto w-full lg:w-[168px]`}
        onClick={handelAddToCart}
      >
        Add to Cart
      </AddToCartButton>
    </div>
  );
};

export default SamplesSelector;
