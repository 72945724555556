import React, { useMemo, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useOutletContext } from "react-router-dom";
import CardWrapper from "../../../components/cardWrapper";
import useStepper from "../../../components/stepper/hooks/useStepper";
import { generateShapesAndMaterialsSteps } from "../../../utils/designs";
import { formatToCurrency } from "../../../utils/numbers";
import { DESIGN_CATEGORY, LAYOUT_TYPES } from "../../../constants/designs";
import { COLORS_ICON_URL, MATERIALS_ICON_URL } from "../../../constants/iconsUrls";
import { setShape, setMaterial, setLayout } from "../../../features/designSlice";
import { CURRENCY } from "../../../constants/common";

function OptionsContainer({ setSteps, options, elements, selectShape, selectMaterial }) {
  const [showAllMaterialTypeOptions, setShowAllMaterialTypeOptions] = useState({});
  const selectedLayout = useSelector((state) => state.design.layout);
  const { currentStepData } = useStepper();

  const isLayout = currentStepData?.category === DESIGN_CATEGORY.LAYOUT;
  const isMaterial = currentStepData?.category === DESIGN_CATEGORY.MATERIAL;

  const groupedMaterialOptions = useMemo(() => {
    const grouped = {};
    if (!isMaterial) {
      return grouped;
    }

    options.forEach((option) => {
      const { material_subtype } = option;
      if (!grouped[material_subtype]) {
        grouped[material_subtype] = [];
      }
      grouped[material_subtype].push(option);
    });
    return grouped;
  }, [options]);

  if (isMaterial) {
    return (
      <div className="flex max-h-[300px] flex-col space-y-3 overflow-y-auto pr-3 text-primary">
        {Object.keys(groupedMaterialOptions).map((materialSubType) => {
          return (
            <div className="flex flex-col space-y-1">
              <span className="text-[14px]">{materialSubType}</span>
              <div key={materialSubType} className="grid grid-cols-4 gap-4">
                {groupedMaterialOptions[materialSubType].map((option, index) => {
                  if (!showAllMaterialTypeOptions[materialSubType] && index > 2) {
                    return;
                  }
                  return (
                    <OptionCard
                      key={index}
                      option={option}
                      elements={elements}
                      setSteps={setSteps}
                      selectMaterial={selectMaterial}
                    />
                  );
                })}

                {groupedMaterialOptions[materialSubType].length > 3 && (
                  <div>
                    <div className="flex aspect-video w-full items-center justify-center">
                      <span
                        className="cursor-pointer text-[14px] font-medium underline"
                        onClick={() =>
                          setShowAllMaterialTypeOptions((prev) => {
                            return {
                              ...prev,
                              [materialSubType]: !prev[materialSubType],
                            };
                          })
                        }
                      >
                        {showAllMaterialTypeOptions[materialSubType] ? "View Less" : "View More"}
                      </span>
                    </div>
                    <span />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div
      className={`${
        isLayout
          ? "flex w-full flex-wrap justify-evenly gap-3"
          : "grid max-h-[300px] grid-cols-3 gap-4 overflow-y-auto pr-3"
      }`}
    >
      {options?.map((option, index) => {
        return (
          <OptionCard
            key={index}
            option={option}
            elements={elements}
            setSteps={setSteps}
            selectShape={selectShape}
            selectMaterial={selectMaterial}
          />
        );
      })}
    </div>
  );
}

const OptionCard = ({ option, elements, setSteps, selectShape, selectMaterial }) => {
  const dispatch = useDispatch();
  const { getState } = useStore();
  const { layouts } = useOutletContext() || {};
  const { currentStepData, nextStep, nextButtonDisabled } = useStepper();
  const selectedLayout = useSelector((state) => state.design.layout);
  const elementsSelectedOptions = useSelector((state) => state.design.elements);
  const priceMode = useSelector((state) => state.design.priceMode);

  const isLayout = currentStepData?.category === DESIGN_CATEGORY.LAYOUT;
  const isLayoutBody = currentStepData?.type === LAYOUT_TYPES.LAYOUT_BODY;
  const isLayoutSize = currentStepData?.type === LAYOUT_TYPES.LAYOUT_SIZE;
  const isShape = currentStepData?.category === DESIGN_CATEGORY.SHAPE;
  const isMaterial = currentStepData?.category === DESIGN_CATEGORY.MATERIAL;

  const isSelected = isLayoutBody
    ? option?.id === selectedLayout?.layout_body?.id
    : isLayoutSize
    ? option?.id === selectedLayout?.layout_size?.id
    : isShape
    ? elementsSelectedOptions[currentStepData?.elementId]?.shape?.id === option?.id
    : elementsSelectedOptions[currentStepData?.elementId]?.material &&
      elementsSelectedOptions[currentStepData?.elementId]?.material[currentStepData?.materialName]
        ?.id === option?.id;

  const handleSelectLayout = (layout) => {
    dispatch(setLayout({ layout_part: layout, type: currentStepData?.type }));
    if (currentStepData?.type === LAYOUT_TYPES.LAYOUT_SIZE) {
      let selectedLayout = getState().design.layout;
      if(selectedLayout?.layout_size){
        const compatibility_list = selectedLayout?.layout_size?.compatible_layouts || []
        const  compatabile_layout_body = layouts?.layout_body.filter((layout_body) => compatibility_list.includes(layout_body?.id));
        setSteps(prevSteps => 
          prevSteps.map(step => ({
            ...step,
            subSteps: step.subSteps.map(subStep => ({
              ...subStep,
              data: {
                ...subStep.data,
                options: step?.label === DESIGN_CATEGORY.LAYOUT && subStep?.data?.type === LAYOUT_TYPES.LAYOUT_BODY ? compatabile_layout_body : subStep.data.options
              }
            }))
          }))
        );
        if(selectedLayout?.layout_body){
          const found = compatabile_layout_body.find((layout_body) => layout_body?.id === selectedLayout?.layout_body?.id);
          if(!found){
            dispatch(setLayout({ layout_part: selectedLayout?.layout_body, type: LAYOUT_TYPES.LAYOUT_BODY }))
          }
        }
      }
    }
  };

  const handleSelectShape = (shape, elementId, elementName) => {
    let selectedElements = getState().design.elements;
    let elementIndex = null;
    for (let i = 0; i < elements.length; i++) {
      if (elements[i]?.id === elementId) {
        elementIndex = i;
        break;
      }
    }
    selectShape(shape?.id, elementIndex, elements, selectedElements);
    selectedElements = getState().design.elements;
    selectMaterial(null, 0, 0, elements, selectedElements);
    selectedElements = getState().design.elements;
    const [shapesSteps, materialsSteps] = generateShapesAndMaterialsSteps(
      elements,
      selectedElements
    );
    setSteps((steps) => [
      steps[0],
      {
        label: DESIGN_CATEGORY.SHAPE,
        icon: COLORS_ICON_URL,
        subSteps: shapesSteps,
      },
      {
        label: DESIGN_CATEGORY.MATERIAL,
        icon: MATERIALS_ICON_URL,
        subSteps: materialsSteps,
      },
    ]);
  };

  const handleSelectMaterial = (material, elementId, elementName, materialName) => {
    dispatch(
      setMaterial({
        material: { ...material },
        elementId,
        elementName,
        materialName,
      })
    );
    let selectedElements = getState().design.elements;
    selectMaterial(null, 0, 0, elements, selectedElements);
    selectedElements = getState().design.elements;
    const [shapesSteps, materialsSteps] = generateShapesAndMaterialsSteps(
      elements,
      selectedElements
    );
    setSteps((steps) => [
      steps[0],
      {
        label: DESIGN_CATEGORY.SHAPE,
        icon: COLORS_ICON_URL,
        subSteps: shapesSteps,
      },
      {
        label: DESIGN_CATEGORY.MATERIAL,
        icon: MATERIALS_ICON_URL,
        subSteps: materialsSteps,
      },
    ]);
  };

  const handleOptionClick = (option) => {
    isLayout
      ? handleSelectLayout(option)
      : isShape
      ? handleSelectShape(option, currentStepData?.elementId, currentStepData?.elementName)
      : handleSelectMaterial(
          option,
          currentStepData?.elementId,
          currentStepData?.elementName,
          currentStepData?.materialName
        );
  };

  const handleOptionDoubleClick = (option) => {
    handleOptionClick(option);

    if (!nextButtonDisabled) {
      nextStep();
    }
  };

  return (
    <div className={`relative flex max-w-[180px] w-full flex-col space-y-1`}>
      <CardWrapper
        isSelected={isSelected}
        onClick={() => handleOptionClick(option)}
        onDoubleClick={() => handleOptionDoubleClick(option)}
        className={`aspect-video w-full ${!isLayout ? "border-0" : "border-1 p-3"} ${
          isSelected && "scale-[80%] border-2"
        }`}
      >
        <img
          key={option?.name}
          src={option?.image}
          alt={option?.name}
          loading="lazy"
          className={`h-full w-full ${!isLayout && "object-cover"}`}
        />
      </CardWrapper>
      {isLayout ? (
        <div className="flex flex-col space-y-1 text-center text-[14px] text-primary">
          <span>{option?.name}</span>
          {(isLayoutSize && option?.dimensions) && <span>{"(" + option?.dimensions + ")"}</span>}
        </div>
      ) : (
        <span className="text-[14px] text-primary">
          {formatToCurrency(option?.price, CURRENCY, option?.range_price_percentage, priceMode)}
        </span>
      )}
    </div>
  );
};

export default OptionsContainer;
