import React from "react";
import { useOutletContext } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import ImageContainer from "../../../components/imageContainer";
import CountDown from "../../../components/countdown";
import useUser from "../../../hooks/useUser";
import useSnackbar from "../../../components/snackbar/hooks/useSnackbar";
import useGetFullImage from "../../../hooks/useGetFullImage";
import SvgIcon from "../../../components/svgIcon";
import { updateDesign, findFullDesignImage } from "../../../apis/designsApi";
import { markAsUnchanged, setLastDisplayedImage } from "../../../features/designSlice";
import { getErrorMessage } from "../../../utils/errors";
import { CART_ITEMS_QUERY_KEY } from "../../../queries-keys/cartItems";
import { SESSION_QUERY_KEY } from "../../../queries-keys/sessions";
import { SAVE_ICON_URL } from "../../../constants/iconsUrls";
import { DEFAULT_DESIGN_IMAGE } from "../../../constants/imagesUrls";

const MEDIA_HOST = process.env.REACT_APP_MEDIA_HOST;
const FRONT_URL = process.env.REACT_APP_FRONTEND_URL;
const DESIGNS_IMAGES_PATH = process.env.REACT_APP_DESIGNS_IMAGES_PATH

const TwoDViewer = ({}) => {
  const { sessionRemainingTime } = useOutletContext() || {};
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { user } = useUser();
  const { handleOpenSnackbar } = useSnackbar();
  const design = useSelector((state) => state.design);
  const isDesignChanged = useSelector((state) => state.design.isChanged);
  const designImage = useSelector((state) => state.design.image);
  const lastDisplayedImage = useSelector((state) => state.design.lastDisplayedImage);
  const isLastChangeMaterial = useSelector((state) => state.design.isLastChangeMaterial);

  const { mutate: updateDesignMutation, isLoading: isSavingDesign } = useMutation(
    (updatedData) => updateDesign(design?.id, updatedData),
    {
      onError: (error) => {
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
      onSuccess: () => {
        dispatch(markAsUnchanged());
        queryClient.invalidateQueries(CART_ITEMS_QUERY_KEY(user?.id));
      },
    }
  );

  const { image_name } = useGetFullImage(designImage);

  const handleDesignSaving = () => {
    if (!isDesignChanged) {
      return;
    }

    const { layout, elements, totalPrice: price } = design;

    updateDesignMutation({
      layout_body: layout.layout_body,
      layout_size: layout.layout_size,
      elements,
      image: String(DESIGNS_IMAGES_PATH) + "/" + image_name + ".png",
      price,
    });
  };

  return (
    <div className="relative aspect-video overflow-hidden rounded-lg border">
      <div className="absolute left-3 top-2 z-[1] lg:top-3">
        <CountDown
          time={sessionRemainingTime}
          onExpire={() => queryClient.invalidateQueries(SESSION_QUERY_KEY())}
          beforeExpire={handleDesignSaving}
        />
      </div>
      <div className="absolute right-3 top-2 z-[1] lg:top-3">
        {isSavingDesign ? (
          <AiOutlineLoading3Quarters className="shrink-0 animate-spin text-[20px] text-primary lg:text-[30px]" />
        ) : (
          <SvgIcon
            src={SAVE_ICON_URL}
            className={`shrink-0 h-[30px] lg:h-[40px] ${
              isDesignChanged
                ? "cursor-pointer !text-highlight hover:!text-red-700"
                : "text-gray-300"
            }`}
            onClick={handleDesignSaving}
          />
        )}
      </div>
      <ImageContainer
        imageMagnifier
        containerClassName={"h-full w-full"}
        imgClassName={"w-full h-full object-cover"}
        src={
          image_name ?
          MEDIA_HOST + "/" + String(DESIGNS_IMAGES_PATH) + "/" + image_name + ".png"
          : isLastChangeMaterial && lastDisplayedImage ?
          lastDisplayedImage
          : DEFAULT_DESIGN_IMAGE
        }
        onLoad={({ currentTarget }) => {
          if (currentTarget.src !== FRONT_URL + DEFAULT_DESIGN_IMAGE) {
            dispatch(setLastDisplayedImage(currentTarget.src));
          }
        }}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src =
            isLastChangeMaterial && lastDisplayedImage ? lastDisplayedImage : DEFAULT_DESIGN_IMAGE;
        }}
        alt="Design Image"
      />
    </div>
  );
};

export default TwoDViewer;
