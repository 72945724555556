export const  replacePlaceholders = (template, replacements) => {
  if(!template) {
    return;
  }

  return template.replace(/\{(.*?)\}/g, (match, key) => {
      return replacements[key] !== undefined ? replacements[key] : match;
  });
}


export const parseAndRenderTextWithLinks = (text) => {
    if(!text) {
      return [];
    }
    
    const regex = /<a\s+href="([^"]+)">([^<]+)<\/a>/g;
  
    let result = [];
    let lastIndex = 0;
  
    text.replace(regex, (match, href, linkText, offset) => {
      result.push(text.slice(lastIndex, offset));
  
      const domain = new URL(href).hostname;
      result.push(
        <a
          className="hover:underline hover:underline-offset-2"
          key={offset}
          href={href}
        >
          {domain}
        </a>
      );
  
      lastIndex = offset + match.length;
    });
  
    result.push(text.slice(lastIndex));
  
    return result;
  };