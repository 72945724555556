import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import MainButton from "../../../components/customButtons/MainButton";
import usePayment from "../../../hooks/usePayment";
import useUser from "../../../hooks/useUser";
import ROUTES from "../../../constants/routes";
import { getFormattedDate, formatDateToTime } from "../../../utils/dates";
import {formatToCurrency} from "../../../utils/numbers";
import { CURRENCY } from "../../../constants/common";

const BookingDetailsCard = ({ bookingDetails }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, user } = useUser();

  const { createPaymentMutation, isPaymentInProgress } = usePayment();

  const handleBookingClick = () => {
    if (!isLoggedIn || !user) {
      return navigate(ROUTES.SIGN_IN.path, { state: { from: location } });
    }

    createPaymentMutation({
      amount: bookingDetails.timeSlot?.price,
      booking_slots: [bookingDetails.timeSlot?.id],
    });
  };

  return (
    <div className="col-span-2 flex h-fit flex-col space-y-5 rounded-md bg-gray-100 p-5 lg:col-span-1 lg:p-10">
      {isPaymentInProgress && (
        <div className="fixed left-0 top-0 z-[100] !m-0 flex h-screen w-screen items-center justify-center bg-black bg-opacity-50">
          <AiOutlineLoading3Quarters
            size={50}
            className="shrink-0 animate-spin text-primary"
          />
        </div>
      )}

      <span className="text-[18px] font-medium">Overview</span>
      <div>
        <span className="mr-5 text-[14px] font-light">Date: </span>
        <span className="text-[18px]">
          {getFormattedDate(bookingDetails.date)}
        </span>
      </div>
      <div>
        <span className="mr-5 text-[14px] font-light">Time: </span>
        <span className="text-[18px]">
          {formatDateToTime(bookingDetails.timeSlot?.start)}
        </span>
      </div>
      <div>
        <span className="mr-5 text-[14px] font-light">Price: </span>
        <span className="text-[18px]">
          {bookingDetails.timeSlot?.price
            ? formatToCurrency(bookingDetails.timeSlot?.price, CURRENCY)
            : ""}
        </span>
      </div>
      <div className="!mt-5 space-y-1 text-[12px] font-light">
        <p>A reminder will be sent a day before the scheduled appointment.</p>
        <p>
          Cancellations are not possible 24 hours prior to the scheduled
          appointment
        </p>
      </div>
      <MainButton
        onClick={handleBookingClick}
        disabled={
          !bookingDetails.date ||
          !bookingDetails.timeSlot ||
          isPaymentInProgress
        }
        className="h-[40px] text-[14px]"
      >
        {isPaymentInProgress ? "Payment In Progress..." : "Book"}
      </MainButton>
    </div>
  );
};

export default BookingDetailsCard;
