import React, { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLastDimensionFilter } from "../../../features/designSlice";

import useFilterLogic from "../hooks/useFilterLogic";
import useStepper from "../../../components/stepper/hooks/useStepper";

const DimensionFilter = () => {
  const dispatch = useDispatch();
  const lastDimensionFilter = useSelector((state) => state.design.lastDimensionFilter);
  const {
    filters: { dimensionsFilters },
    selectedFilters: { selectedDimensionsFilters },
    selectDimensionFilter,
  } = useFilterLogic();
  const elementsSelectedOptions = useSelector((state) => state.design.elements);
  const { currentStepData } = useStepper();

  useLayoutEffect(() => {
    currentStepData?.options.forEach((option) => {
      if (
        elementsSelectedOptions[currentStepData?.elementId]?.shape?.id ===
        option?.id
      ) {
        if (option?.dimension_tags && option?.dimension_tags.length > 0) {
          if(option?.dimension_tags.includes(lastDimensionFilter)) {
            selectDimensionFilter(lastDimensionFilter);
          }
          else{
            selectDimensionFilter(option?.dimension_tags[0]);
            dispatch(setLastDimensionFilter({newDimensionFilter: option?.dimension_tags[0]}));
          }
        }
      }
    });
  }, [dimensionsFilters, elementsSelectedOptions]);

  const handleSelectDimensionFilter = (dimensionsFilter) => {
    selectDimensionFilter(dimensionsFilter);
    dispatch(setLastDimensionFilter({newDimensionFilter: dimensionsFilter}));
  };

  return (
    <div className="flex h-fit w-fit max-w-full flex-wrap">
      {dimensionsFilters?.map((dimensionsFilter, index) => {
        const isSelected = !!selectedDimensionsFilters.find(
          (filter) => filter === dimensionsFilter,
        );
        return (
          <button
            key={index}
            className={` h-6 w-[80px] shrink-0 bg-gray-100 px-3 py-1 text-[11px] text-secondary first:rounded-l last:rounded-r ${isSelected && "rounded bg-highlight text-white"}`}
            onClick={() => handleSelectDimensionFilter(dimensionsFilter)}
          >
            {dimensionsFilter}
          </button>
        );
      })}
    </div>
  );
};

export default DimensionFilter;
